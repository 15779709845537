import * as types from '../mutation-types';

const state = {
    headerColor: { r: 255, g: 255, b: 255 },
    buttonAndLinkColor: { r: 255, g: 255, b: 255 },
    logoFile: null,
    logoUrl: null,
    logoWidth: null,
    logoHeight: null,
}

const mutations = {
    [types.setHeaderColor](state, t) {
        state.headerColor = t;
    },
    [types.setButtonAndLinkColor](state, t) {
        state.buttonAndLinkColor = t;
    },
    [types.setLogoUrl](state, t) {
        state.logoUrl = t;
    },
    [types.setLogoFile](state, t) {
        state.logoFile = t;
    },
    [types.setLogoWidth](state, t) {
        state.logoWidth = t;
    },
    [types.setLogoHeight](state, t) {
        state.logoHeight = t;
    }
}

const getters = {
    getHeaderColor: (state) => {
        return state.headerColor;
    },
    getButtonAndLinkColor: (state) => {
        return state.buttonAndLinkColor;
    },
    getLogoUrl: (state) => {
        return state.logoUrl;
    },
    getLogoFile: (state) => {
        return state.logoFile;
    },
    getLogoWidth: (state) => {
        return state.logoWidth;
    },
    getLogoHeight: (state) => {
        return state.logoHeight;
    }
}


export default ({
    state,
    mutations,
    getters
})