import * as types from '../mutation-types'


const state = {
  token: null,
  merchantName: null,
  isMerchantVerified: false,
  isMerchantActivated: false,
  userRole: null,
  merchantID: null,
  emailID: null
}

const mutations = {
  [types.setToken](state, t) {
    state.token = t
  },
  [types.setMerchantName](state, n) {
    state.merchantName = n
  },
  [types.setIsMerchantVerified](state, b) {
    state.isMerchantVerified = b
  },
  [types.setIsMerchantActivated](state, b) {
    state.isMerchantActivated = b
  },
  [types.setUserRole](state, b) {
    state.userRole = b
  },
  [types.setMerchantID](state, b) {
    state.merchantID = b
  },
  [types.setEmailID](state, b) {
    state.emailID = b
  }
}

const getters = {
  getToken: function (state) {
    return state.token
  },
  getMerchantName: function (state) {
    return state.merchantName
  },
  getIsMerchantVerified: function (state) {
    return state.isMerchantVerified
  },
  getIsMerchantActivated: function (state) {
    return state.isMerchantActivated
  },
  getUserRole: function (state) {
    return state.userRole
  },
  getMerchantID: function (state) {
    return state.merchantID
  },
  getEmailID: function (state) {
    return state.emailID
  }
}

export default ({
  state,
  mutations,
  getters
})