import * as types from '../mutation-types'


const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isSandboxActive: true,
}

const mutations = {
  [types.toggleSidebarDesktop](state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  [types.toggleSidebarMobile] (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  [types.set] (state, [variable, value]) {
    state[variable] = value
  },
  [types.toggleSideBar] (state,t) {
    state.sidebarMinimize = t
  },
  [types.toggleSandboxMode] (state,b) {
    state.isSandboxActive = b
  },
}

const getters = {
  getSideBarShow: function(state) {
    return state.sidebarShow
  },
  getSideBarMini: function(state) {
    return state.sidebarMinimize
  },
  getIsSandboxActive: function(state) {
    return state.isSandboxActive
  }
}

export default ({
  state,
  getters,
  mutations,
})