import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import login from "./modules/login";
import sidebar from "./modules/sidebar";
import customization from "./modules/customization";
import paymentLink from "./modules/paymentLink";
import permissions from "./modules/permissions";
import userManagement from "./modules/userManagement";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    login,
    sidebar,
    customization,
    paymentLink,
    permissions,
    userManagement,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage
    })
  ]
})
