import translationsEnglish from "@/../static/i18n/en.json";
import translationsIndo from "@/../static/i18n/id.json";

export const languages = {
  en: translationsEnglish,
  id: translationsIndo,
};

export const paymentLink = {
  url:
    process.env.VUE_APP_DPAY_CHECKOUT_HOST_URL +
    process.env.VUE_APP_PAYMENT_LINK_PATH,
};

export const contactUrl = {
  url: "http://www.durian.money/pages/contact_us/index.html",
};

export const images = {
  warningTick: "../../assets/img/warning.png",
  successTick: "../../assets/img/success.png",
};

export const role = {
  maker: "Maker",
  approver: "Approver",
  admin: "Admin",
};

export const polling = {
  maxAttemps: parseInt(process.env.VUE_APP_POLLING_MAX_ATTEMPTS, 10),
  pollingInterval: 1000 * parseInt(process.env.VUE_APP_POLLING_INTERVAL, 10),
  pollingAttempInterval:
    1000 * parseInt(process.env.VUE_APP_POLLING_ATTEMPT_INTERVAL, 10),
};

export const sandboxEventEmitter = {
  prefix: "sandbox-toggled-",
};

export const permissions = {
  homeView: "home.view",
  disbursementCreate: "disbursement.create",
  disbursementApprove: "disbursement.approve",
  userManagementCreate: "usermanagement.create",
  userManagementView: "usermanagement.view",
  settingsView: "settings.view",
  refundApprove: "refund.approve",
  refundCreate: "refund.create",
  refundReject: "refund.reject",
  analyticsView: "analytics.view",
  homeView: "home.view",
  orderView: "order.view",
  paymentView: "payment.view",
  promoView: "promo.view",
  paymentView: "payment.view",
  promoCreate: "promo.create",
  topupCreate: "topup.create",
  topupView: "topup.view",
};
