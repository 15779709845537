import * as types from '../mutation-types';

const defaultState = () => {
    return {
        expiryDays: 3,
        amount: "",
        customerName: "",
        customerEmail: "",
        customerPhoneNumber: "",
        customerAddress: "",
        customerCity: "",
        customerState: "",
        customerPostalCode: "",
        items: [],
        notes: ""
    }
}
const state = defaultState();

const mutations = {
    [types.setAmount](state, t) {
        state.amount = t;
    },
    [types.setExpiryDays](state, t) {
        state.expiryDays = t;
    },
    [types.setCustomerName](state, t) {
        state.customerName = t;
    },
    [types.setCustomerPhoneNumber](state, t) {
        state.customerPhoneNumber = t;
    },
    [types.setCustomerEmail](state, t) {
        state.customerEmail = t;
    },
    [types.setCustomerAddress](state, t) {
        state.customerAddress = t;
    },
    [types.setCustomerCity](state, t) {
        state.customerCity = t;
    },
    [types.setCustomerState](state, t) {
        state.customerState = t;
    },
    [types.setCustomerPostCode](state, t) {
        state.customerPostCode = t;
    },
    [types.setItems](state, t) {
        state.items[t.index] = t.value;
    },
    [types.resetItem](state) {
        state.items = [];
    },
    [types.removeItem](state, index) {
        state.items.splice(index, 1);
    },
    [types.addItem](state, t) {
        state.items.push(t);
    },
    [types.setNotes](state, t) {
        state.notes = t;
    },
    [types.resetPaymetLinkState](state) {
        Object.assign(state, defaultState())
    }
}

const getters = {
    getAmount: (state) => {
        return state.amount;
    },
    getExpiryDays: (state) => {
        return state.expiryDays;
    },
    getCustomerName: (state) => {
        return state.customerName;
    },
    getCustomerEmail: (state) => {
        return state.customerEmail;
    },
    getCustomerPhoneNumber: (state) => {
        return state.customerPhoneNumber;
    },
    getCustomerAddress: (state) => {
        return state.customerAddress;
    },
    getCustomerCity: (state) => {
        return state.customerCity;
    },
    getCustomerState: (state) => {
        return state.customerState;
    },
    getCustomerPostCode: (state) => {
        return state.customerPostCode;
    },
    getItems: (state) => {
        return state.items;
    },
    getNotes: (state) => {
        return state.notes;
    }
}

export default ({
    state,
    mutations,
    getters
})