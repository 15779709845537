import Vue from 'vue';
import Router from 'vue-router';
import { role } from '../../config.js'
import { store } from '../store/index.js';
import { permissions } from '../../config.js';
import * as getterTypes from '../store/getter-types';
import * as name from './routeName.js';
// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Home = () => import('@/views/home/Home')
const HomeOld = () => import('@/views/HomeOld')
const Transactions = () => import('@/views/Transactions')
const UserVerificationView = () => import('@/views/UserVerificationView')
const SignupView = () => import('@/views/SignupView')
const Settings = () => import('@/views/settings/Settings')
const Orders = () => import('@/views/orders/Orders')
const OrderDetails = () => import('@/views/orders/OrderDetails')
const CustomerDetails = () => import('@/views/customers/CustomerDetails')
const Payments = () => import('@/views/payments/Payments')
const PaymentDetailsView = () => import('@/views/payments/PaymentDetailsView')
const Customers = () => import('@/views/customers/Customers')
const Settlements = () => import('@/views/settlements/Settlements')
const SettlementDetails = () => import('@/views/settlements/SettlementDetails')
const ResetPassword = () => import('@/views/ResetPassword')
const Analytics = () => import('@/views/analytics/Analytics')
// Users
const Promos = () => import('@/views/promos/Promos');
const NewPromo = () => import('@/views/promos/NewPromo');
const NewWebhook = () => import('@/views/settings/webhooks/NewWebhook');

const Users = () => import('@/views/users/Users');
const User = () => import('@/views/users/User');
const NewUser = () => import('@/views/users/NewUser');
const EditUser = () => import('@/views/users/EditUser');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/login/Login');
const ChangePassword = () => import('@/views/login/ChangePassword');
const Register = () => import('@/views/pages/Register');
const ForgotPassword = () => import('@/views/login/ForgotPassword')
const EmailNotVerified = () => import('@/views/login/EmailNotVerified')

// Payment links
const PaymentLinks = () => import('@/views/paymentLinks/PaymentLinks');
const NewPaymentLink = () => import('@/views/paymentLinks/NewPaymentLink');
const PaymentLinkDetailsView = () => import('@/views/paymentLinks/PaymentLinkDetails.vue');

//Refund
const Refunds = () => import('@/views/refunds/Refunds');
const NewRefund = () => import('@/views/refunds/NewRefund');
const RefundDetail = () => import('@/views/refunds/RefundDetail');

//Disbursements
const Disbursements = () => import('@/views/disbursements/Disbursements');
const TopupDisbursements = () => import('@/views/disbursements/TopupDisbursements');
const CreateBatchDisbursements = () => import('@/views/disbursements/CreateBatchDisbursements');
const DisbursementsDetails = () => import('@/views/disbursements/DisbursementDetails');
const DisbursementsBatchInfo = () => import('@/views/disbursements/DisbursementsBatchInfo');

//User Management
const AccessDenied = () => import('@/views/userManagement/AccessDenied');
const Profile = () => import('@/views/userManagement/Profile');
const UserList = () => import('@/views/userManagement/UserList');
const InviteUser = () => import('@/views/userManagement/InviteUser');
Vue.use(Router);

export default new Router({
	mode: 'hash', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'active',
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes(),
});

function configRoutes() {
	return [
		{
			path: '/',
			redirect: '/login'
		},
		{
			path: '/',
			component: TheContainer,
			meta: {
				requiresAuth: true
			},
			children: [
				{
					path: 'home',
					name: 'Home',
					component: Home,
					meta: {
						requiresAuth: true,
						permissionType: permissions.homeView
					}
				},
				{
					path: 'homeold',
					name: 'HomeOld',
					component: HomeOld,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'transactions',
					name: 'Transcations',
					component: Transactions,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'settings',
					meta: {
						label: 'Settings',
						requiresAuth: true,
						permissionType: permissions.settingsView
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Settings',
							component: Settings,
							meta: {
								label: 'Overview',
								requiresAuth: true,
								permissionType: permissions.settingsView
							}
						},
						{
							path: '/settings/webhooks/new',
							meta: {
								label: 'Create New Webhook',
								requiresAuth: true,
								permissionType: permissions.settingsView
							},
							name: 'NewWebhook',
							component: NewWebhook
						},
					]
				},
				{
					path: 'orders',
					meta: {
						label: 'Orders',
						requiresAuth: true
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Orders',
							component: Orders,
							meta: {
								label: 'Overview',
								requiresAuth: true
							}
						},
						{
							path: ':id',
							meta: {
								label: 'Order Details',
								requiresAuth: true
							},
							name: 'Order Details',
							component: OrderDetails,
						},
					]
				},
				{
					path: 'payments',
					meta: {
						label: 'Payments',
						requiresAuth: true
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Payments',
							component: Payments,
							meta: {
								label: 'Overview',
								requiresAuth: true
							}
						},
						// {
						// 	path: ':id',
						// 	meta: {
						// 		label: 'Payment Details',
						// 		requiresAuth: true
						// 	},
						// 	name: 'Payment Details',
						// 	component: PaymentDetails,
						// 	meta: {
						// 		requiresAuth: true
						// 	}
						// },
						{
							path: ':id',
							meta: {
								label: 'Payment Details',
								requiresAuth: true
							},
							name: 'Payment Details',
							component: PaymentDetailsView,
						},
					]
				},
				{
					path: 'payment_links',
					meta: {
						label: 'Payment Links',
					},
					component: {
						render(c) {
							return c('router-view');
						},
					},
					children: [
						{
							path: '',
							name: 'Payment Links',
							component: PaymentLinks,
							meta: {
								label: 'Overview',
								requiresAuth: true,
							},
						},
						{
							path: 'detail/:id',
							name: 'Payment Links Detail',
							component: PaymentLinkDetailsView,
							meta: {
								requiresAuth: true,
							},
						},
						{
							path: 'create',
							meta: {
								label: 'Create New',
								requiresAuth: true,
							},
							name: 'Create New Payment Link',
							component: NewPaymentLink,
						},
					],
				},
				{
					path: 'customers',
					meta: {
						requiresAuth: true
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Customers',
							component: Customers,
							meta: {
								requiresAuth: true
							}
						},
						{
							path: ':id',
							meta: {
								label: 'Customer Details',
								requiresAuth: true
							},
							name: 'Customer Details',
							component: CustomerDetails,
						},
					]
				},
				{
					path: 'settlements',
					meta: {
						label: 'Settlements',
						requiresAuth: true
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Settlements',
							component: Settlements,
							meta: {
								label: 'Overview',
								requiresAuth: true
							}
						},
						{
							path: ':id',
							meta: {
								label: 'Settlement Details',
								requiresAuth: true
							},
							name: 'Settlement Details',
							component: SettlementDetails,
						}
					]
				},
				{
					path: 'promos',
					meta: {
						label: 'Promos',
						requiresAuth: false
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Promos',
							component: Promos,
							meta: {
								label: 'Overview',
								requiresAuth: false
							}
						},
						{
							path: 'new',
							meta: {
								label: 'Create New',
								requiresAuth: false
							},
							name: 'NewPromo',
							component: NewPromo
						},
					]
				},
				{
					path: 'users',
					meta: {
						label: 'Users',
						requiresAuth: true
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Users',
							component: Users,
							meta: {
								requiresAuth: true
							}
						},
						{
							path: 'new',
							meta: {
								label: 'New User',
								requiresAuth: true
							},
							name: 'NewUser',
							component: NewUser
						},
						{
							path: ':id',
							meta: {
								label: 'User Details',
								requiresAuth: true
							},
							name: 'User',
							component: User,
						},
						{
							path: ':id/edit',
							meta: {
								label: 'Edit Details'
							},
							name: 'EditUser',
							component: EditUser
						}
					]
				},
				{
					path: 'refunds',
					meta: {
						label: 'Refund Management',
						requiresAuth: true,
						featureEnableCheck: getterTypes.isRefundEnabled,
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Refund',
							component: Refunds,
							meta: {
								label: 'Overview',
								requiresAuth: true,
								featureEnableCheck: getterTypes.isRefundEnabled,
							}
						},
						{
							path: 'new',
							name: 'New Refund',
							component: NewRefund,
							meta: {
								label: 'Create New',
								requiresAuth: true,
								featureEnableCheck: getterTypes.isRefundEnabled,
							}
						},
						{
							path: ':id',
							name: 'Refund Detail',
							component: RefundDetail,
							meta: {
								requiresAuth: true,
								label: 'Refund Detail',
								featureEnableCheck: getterTypes.isRefundEnabled,
							},
						},
					]
				},
				{
					path: 'analytics',
					name: 'Analytics',
					component: Analytics,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'disbursements',
					meta: {
						label: 'Disbursements',
						requiresAuth: true,
						featureEnableCheck: getterTypes.isDisbursementEnabled,
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							name: 'Disbursements',
							component: Disbursements,
							meta: {
								label: 'Overview',
								requiresAuth: true,
								featureEnableCheck: getterTypes.isDisbursementEnabled,
							}
						},
						{
							path: 'topup',
							name: 'TopupDisbursements',
							component: TopupDisbursements,
							meta: {
								requiresAuth: true,
								featureEnableCheck: getterTypes.isDisbursementEnabled,
							}
						},
						{
							path: 'create',
							name: 'Create Batch Disbursements',
							component: CreateBatchDisbursements,
							meta: {
								requiresAuth: true,
								featureEnableCheck: getterTypes.isDisbursementEnabled,
							}
						},
						{
							path: 'detail/:id',
							meta: {
								label: 'Batch Disbursement Details',
								requiresAuth: true,
								featureEnableCheck: getterTypes.isDisbursementEnabled,
							},
							component: DisbursementsDetails,
						},
						{
							path: 'info/:id',
							meta: {
								name: name.BATCH_DISBURSEMENT_INFO,
								label: 'Batch Disbursement Info',
								requiresAuth: true,
								featureEnableCheck: getterTypes.isDisbursementEnabled,
							},
							component: DisbursementsBatchInfo,
						},
					]
				},
				{
					path: 'user',
					meta: {
						label: "User Management",
						requiresAuth: true,
						permissionType: permissions.userManagementView
					},
					component: {
						render(c) {
							return c('router-view')
						}
					},
					children: [
						{
							path: '',
							component: UserList,
							name: "User List",
							meta: {
								label: "User List",
								requiresAuth: true,
								permissionType: permissions.userManagementView
							}
						},
						{
							path: 'invite',
							component: InviteUser,
							name: "Invite User",
							meta: {
								label: "Invite User",
								requiresAuth: true,
								permissionType: permissions.userManagementCreate
							}
						},
						{
							path: 'change-password',
							name: 'ChangePassword',
							component: ChangePassword,
							meta: {
								label: "Change Password",
								requiresAuth: true,
							}
						},
						{
							path: 'profile',
							name: 'Profile',
							component: Profile,
							metadata: {
								label: "Profile",
								requiresAuth: true
							}
						},
					]
				},
				{
					path: '/access_denied',
					component: AccessDenied
				},
			]
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/verify',
			meta: {
				requiresAuth: false
			},
			component: UserVerificationView,
		},
		{
			path: '/signup',
			name: 'SignupView',
			meta: {
				requiresAuth: false
			},
			component: SignupView,
		},
		{
			path: '/forgot_password',
			name: 'ForgotPassword',
			component: ForgotPassword,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/reset_password',
			name: 'ResetPassword',
			component: ResetPassword,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/email_not_verified',
			name: 'EmailNotVerified',
			component: EmailNotVerified,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/pages',
			redirect: '/pages/404',
			name: 'Pages',
			component: {
				render(c) { return c('router-view') }
			},
			children: [
				{
					path: '404',
					name: 'Page404',
					component: Page404
				},
				{
					path: '500',
					name: 'Page500',
					component: Page500
				},
				{
					path: 'register',
					name: 'Register',
					component: Register
				}
			]
		},

	]
}
