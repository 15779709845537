// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['550.78 148.44',`
  <g>
  <g>
    <path class="st1" d="M43.91,80.23V64.52c0-0.91-0.49-1.76-1.28-2.21l-15.34-8.86c-1.7-0.98-3.83,0.25-3.83,2.21v33.43
      c0,1.97,2.13,3.2,3.83,2.21l15.34-8.86C43.43,81.99,43.91,81.15,43.91,80.23z"/>
    <path class="st2" d="M46.63,59.84l13.69-7.91c0.79-0.46,1.28-1.3,1.28-2.21V32c0-1.97-2.13-3.2-3.83-2.21L28.73,46.55
      c-1.7,0.98-1.7,3.44,0,4.43l15.34,8.86C44.87,60.29,45.84,60.29,46.63,59.84z"/>
    <path class="st1" d="M60.32,92.99L46.48,85c-0.79-0.46-1.77-0.46-2.56,0l-15.34,8.86c-1.7,0.98-1.7,3.44,0,4.43l29.19,16.85
      c1.7,0.98,3.83-0.25,3.83-2.21V95.21C61.6,94.29,61.12,93.45,60.32,92.99z"/>
    <path class="st2" d="M82.22,37.71v0.08l-13.92-8.04c-1.7-0.98-3.83,0.25-3.83,2.21v17.72c0,0.91,0.49,1.76,1.28,2.21l13.63,7.87
      c0.79,0.46,1.77,0.46,2.56,0l15.41-8.9c1.7-0.98,1.7-3.44,0-4.43L82.22,37.71z"/>
    <path class="st3" d="M102.67,41.79V23.41c0-1.97-2.13-3.2-3.83-2.21l-15.34,8.86c-0.79,0.46-1.28,1.3-1.28,2.21v0.66
      c0,0.91,0.49,1.76,1.28,2.21l15.34,8.86C100.54,44.99,102.67,43.76,102.67,41.79z"/>
    <path class="st0" d="M82.22,64.37v16.01c0,0.91,0.49,1.76,1.28,2.21l15.34,8.86c1.7,0.98,3.83-0.25,3.83-2.21V55.51
      c0-1.97-2.13-3.2-3.83-2.21l-15.34,8.86C82.7,62.61,82.22,63.46,82.22,64.37z"/>
    <path class="st0" d="M79.52,85.07l-13.78,7.96c-0.79,0.46-1.28,1.3-1.28,2.21v17.72c0,1.97,2.13,3.2,3.83,2.21l29.13-16.82
      c1.7-0.98,1.7-3.44,0-4.43l-15.34-8.86C81.29,84.62,80.31,84.62,79.52,85.07z"/>
  </g>
  <g>
    <path class="st0" d="M174.4,26.45v71.89c0,2.14-2.89,4.39-6.86,4.39c-3.43,0-6.21-2.25-6.21-4.39v-3.11
      c-2.14,4.39-8.04,8.46-14.89,8.46c-9.96,0-18.75-10.5-18.75-21.96v-8.79c0-11.89,8.57-21.75,19.07-21.75
      c6.64,0,11.46,3.96,13.82,8.14V26.45c0-2.57,3.11-4.29,6.96-4.29C171.51,22.16,174.4,23.88,174.4,26.45z M141.51,81.73
      c0,4.93,4.29,9.86,9.32,9.86c5.46,0,9.75-5.68,9.75-7.93V72.95c0-6-5.04-9.64-9.64-9.64c-5.25,0-9.43,4.82-9.43,9.64V81.73z"/>
    <path class="st0" d="M182.01,81.95V56.23c0-2.14,3.11-4.39,6.96-4.39c3.64,0,6.86,2.25,6.86,4.39v25.71
      c0,4.82,4.18,9.64,9.43,9.64c5.25,0,9.32-4.71,9.32-9.64V56.13c0-2.25,3.21-4.29,6.97-4.29c4.07,0,6.86,2.04,6.86,4.29v25.82
      c0,11.57-10.5,21.75-23.14,21.75C192.51,103.7,182.01,93.41,182.01,81.95z"/>
    <path class="st0" d="M262.15,63.3c-6.21,0-12.32,6.11-12.32,15.86v19.18c0,2.14-3.21,4.39-7.07,4.39c-3.96,0-6.75-2.25-6.75-4.39
      V56.13c0-2.57,2.79-4.29,6.75-4.29c3.43,0,6.21,1.71,6.21,4.29v4.39c2.14-4.61,7.28-9.32,13.18-9.32h3.32c2.57,0,5.04,2.79,5.04,6
      c0,3.32-2.46,6.11-5.04,6.11H262.15z"/>
    <path class="st0" d="M288.08,33.73c0,3.54-3.32,6.75-7.5,6.75c-4.29,0-7.61-3.21-7.61-6.75c0-3.64,3.32-6.75,7.61-6.75
      C284.76,26.98,288.08,30.09,288.08,33.73z M287.54,56.13v42.21c0,2.14-3.11,4.39-6.96,4.39c-3.97,0-6.86-2.25-6.86-4.39V56.13
      c0-2.57,2.89-4.29,6.86-4.29C284.43,51.84,287.54,53.55,287.54,56.13z"/>
    <path class="st0" d="M335.75,72.09v26.25c0,2.46-2.89,4.39-6.75,4.39c-3.53,0-6.21-1.93-6.21-4.39v-3.11
      c-3.86,4.39-8.89,8.46-16.29,8.46c-7.61,0-13.93-4.5-13.93-14.25c0-12.32,10.18-15.75,24.96-15.75h4.39V72.3
      c0-4.61-2.36-9.86-9.43-9.86c-8.25,0-9.64,3.32-12.21,3.32c-3.11,0-4.71-3.75-4.71-6.86c0-4.39,9.53-7.71,17.14-7.71
      C329.43,51.2,335.75,61.27,335.75,72.09z M321.93,80.88h-1.61c-7.18,0-13.93,0.75-13.93,6.75c0,3.96,2.46,5.79,5.68,5.79
      c4.39,0,9.86-5.04,9.86-10.29V80.88z"/>
    <path class="st0" d="M357.18,98.34c0,2.14-3.21,4.39-6.96,4.39c-4.07,0-6.86-2.25-6.86-4.39V56.13c0-2.57,2.79-4.29,6.86-4.29
      c3.32,0,6.11,1.71,6.11,4.29v3.21c2.04-4.18,7.18-8.14,14.36-8.14c9.86,0,18.43,9.86,18.43,21.75v25.39
      c0,2.89-3.54,4.39-6.96,4.39c-3.21,0-6.86-1.5-6.86-4.39V72.95c0-4.82-4.18-9.64-9.32-9.64c-4.39,0-8.79,3.64-8.79,9.64V98.34z"/>
    <path class="st0" d="M404.86,54.63v4.93c2.36-4.18,7.29-8.14,14.36-8.14c10.61,0,19.28,9,19.28,20.04v11.68
      c0,10.61-8.89,20.36-18.96,20.36c-6.54,0-12.11-3.86-14.46-8.25v29.68c0,1.29-1.5,2.68-3.54,2.68c-1.93,0-3.43-1.39-3.43-2.68
      V54.63c0-1.5,1.5-2.57,3.43-2.57C403.36,52.05,404.86,53.13,404.86,54.63z M431.54,71.45c0-6.86-6.11-13.61-13.5-13.61
      c-6.43,0-12.96,5.04-12.96,13.61v15c0,3.21,5.57,10.61,13.18,10.61c7.07,0,13.28-6.86,13.28-13.93V71.45z"/>
    <path class="st0" d="M481.14,72.09v27.86c0,1.61-1.39,2.79-3.43,2.79c-1.82,0-3.21-1.18-3.21-2.79v-4.71
      c-3.75,4.29-9,8.25-16.18,8.25c-8.14,0-14.57-4.5-14.57-14.14c0-11.68,10.07-14.68,24.96-14.68h5.46v-2.57
      c0-6.86-3.43-14.57-12.64-14.57c-7.18,0-9.75,3.11-11.46,3.11c-1.82,0-2.79-2.14-2.79-3.75c0-2.79,7.61-5.46,14.46-5.46
      C476.11,51.41,481.14,61.38,481.14,72.09z M474.18,79.48h-4.5c-9.86,0-18.96,0.97-18.96,9.21c0,6.21,4.07,8.89,9.32,8.89
      c6.86,0,14.14-6.43,14.14-11.25V79.48z"/>
    <path class="st0" d="M485.75,55.7c-0.21-0.32-0.32-0.64-0.32-0.96c0-1.82,2.46-3.32,4.29-3.32c1.07,0,2.14,0.43,2.46,1.5
      l14.68,39.43l13.82-39.43c0.43-1.07,1.39-1.5,2.36-1.5c1.71,0,4.28,1.39,4.28,3.43c0,0.21,0,0.64-0.21,0.86l-16.61,45
      c-4.29,11.57-9.64,26.89-21.75,26.89c-1.82,0-3.21-0.75-3.21-3.11c0-1.61,0.54-3.21,2.14-3.21c6.75,0,12.86-9.54,15.53-20.68
      L485.75,55.7z"/>
  </g>
</g>
`]
