import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay, 
  cibCcAmex
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilChart,
  cilPeople,
  cilTransfer,
  cilMoney,
  cilArrowCircleBottom,
  cilArrowCircleTop,
  cilFilter,
  cilLibraryAdd,
  cilBadge,
  cilBank,
  cilHome,
  cilArrowLeft,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilExclamationCircle,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilEqualizer,
  cilUserFollow,
  cilXCircle,
  cilCopy,
  cilSync,
  cilWallet,
  cilWarning,
  cilInfo,
  cisWarning,
  cisInfo,

} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cilChart,
    cilPeople,
    cilTransfer,
    cilMoney,
    cilArrowCircleBottom,
    cilArrowCircleTop,
    cilFilter,
    cilLibraryAdd,
    cilBadge,
    cilBank,
    cilHome,
    cilArrowLeft,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilExclamationCircle,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilEqualizer,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilCopy,
    cilSync,
    cilWallet,
    cilWarning,
    cilInfo,
    cisWarning,
    cisInfo,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard, 
    cibCcVisa, 
    cibStripe, 
    cibPaypal, 
    cibGooglePay,
    cibCcAmex,
  }
)
