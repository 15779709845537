//login
export const toggleSidebarDesktop = "toggleSidebarDesktop";
export const toggleSidebarMobile = "toggleSidebarMobile";
export const setToken = "setToken";
export const setMerchantName = "setMerchantName";
export const toggleSideBar = "toggleSideBar";
export const set = "set";
export const toggleSandboxMode = "toggleSandboxMode";
export const setIsMerchantVerified = "setIsMerchantVerified";
export const setIsMerchantActivated = "setIsMerchantActivated";
export const setUserRole = "setUserRole";
export const setMerchantID = "setMerchantID";
export const setEmailID = "setEmailID";
//customization
export const setHeaderColor = "setHeaderColor";
export const setButtonAndLinkColor = "setButtonAndLinkColor";
export const setLogoUrl = "setLogoUrl";
export const setLogoFile = "setLogoFile";
export const setLogoWidth = "setLogoWidth";
export const setLogoHeight = "setLogoHeight";
//payment links
export const setAmount = "setAmount";
export const setExpiryDays = "setExpiryDays";
export const setCustomerName = "setCustomerName";
export const setCustomerEmail = "setCustomerEmail";
export const setCustomerPhoneNumber = "setCustomerPhoneNumber";
export const setCustomerAddress = "setCustomerAddress";
export const setCustomerCity = "setCustomerCity";
export const setCustomerState = "setCustomerState";
export const setCustomerPostCode = "setCustomerPostCode";
export const setItems = "setItems";
export const addItem = "addItem";
export const removeItem = "removeItem";
export const setNotes = "setNotes";
export const resetItem = "resetItem";
export const resetPaymetLinkState = "resetPaymentLinkState";
//permissions
export const setPageViews = "setPageViews";
export const setIsDisbursementEnabled = "setIsDisbursementEnabled";
export const setIsRefundEnabled = "setIsRefundEnabled";
export const setDefaultPermissionState = "setDefaultPermissionState";
//user management
export const setUserName = "setUserName";
export const setUserEmail = "setUserEmail";
export const setRoleID = "setRoleID";
export const setRole = "setRole";
export const resetUserManagementState = 'resetUserManagementState';
