import * as types from "../mutation-types";

const defaultState = () => {
  return {
    userEmail: "",
    userName: "",
    roleID: 3,
    role: "Admin",
  };
};

const state = defaultState();

const mutations = {
  [types.setUserEmail](state, t) {
    state.userEmail = t;
  },
  [types.setUserName](state, t) {
    state.userName = t;
  },
  [types.setRoleID](state, t) {
    state.roleID = t;
  },
  [types.setRole](state, t) {
    state.role = t;
  },
  [types.resetUserManagementState](state) {
    state.userEmail = "";
    state.userName = "";
  },
};

const getters = {
  getUserName: (state) => {
    return state.userName;
  },
  getUserEmail: (state) => {
    return state.userEmail;
  },
  getUserRoleID: (state) => {
    return state.roleID;
  },
  getUserSelectedRole: (state) => {
    return state.role;
  },
};

export default {
  state,
  mutations,
  getters,
};
