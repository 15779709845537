import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import { store } from "./store";
import { mixin } from "./methods/vueMixins.js";
import Toasted from "vue-toasted";
import BootstrapVue from "bootstrap-vue";
import moment from "moment-timezone";
import { languages } from "@/../config.js";
import VueI18n from "vue-i18n";
import VueJsonToCsv from "vue-json-to-csv";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
Vue.use(VueTimepicker);
Vue.use(VueJsonToCsv);
import { ValidationProvider, ValidationObserver } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueI18n);

const messages = Object.assign(languages);

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages,
});

moment.tz.setDefault("Asia/Jakarta");
Vue.prototype.moment = moment;

Vue.use(BootstrapVue);

Vue.use(Toasted);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$eventHub = new Vue();

Vue.directive("page-access", {
  bind(_, binding, vnode) {
    const pageViews = vnode.context.$store.getters.getPageViews;
    if (!pageViews.includes(binding.value)) {
      vnode.context.$router.push("/access_denied");
    }
  },
});

Vue.directive("element-access", {
  bind(el, binding, vnode) {
    const pageViews = vnode.context.$store.getters.getPageViews;
    if (!pageViews.includes(binding.value)) {
      el.style.display = "none";
    }
  },
});

Vue.mixin({
  mixins: [mixin],
});

new Vue({
  el: "#app",
  router,
  store,
  icons,
  i18n,
  template: "<App/>",
  components: {
    App,
  },
});

router.beforeResolve((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    store.getters.getToken === null
  ) {
    next({ path: "/login" });
  } else {
    if (to.path === "/login" && store.getters.getToken !== null) {
      next({ path: "/home" });
    } else {
      next();
    }
  }

  if (to.meta.permissionType) {
    const pageView = to.meta.permissionType;
    if (!store.getters.getPageViews.includes(pageView)) {
      next({ path: "/access_denied" });
    } else {
      next(true);
    }
  }

  if (to.meta.featureEnableCheck) {
    const getIsFeatureEnabled = to.meta.featureEnableCheck;
    if (!store.getters[getIsFeatureEnabled]) {
      next({ path: "/access_denied" });
    } else {
       next(true);
    }
  }
});