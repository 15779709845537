import { store } from "../store";
import { role } from "../../config.js";
import { permissions } from "../../config.js";
import * as name from '../router/routeName.js';

let mixin = {
    data() {
        return {
            permissions: {
                userManagementCreate: permissions.userManagementCreate,
                promoCreate: permissions.promoCreate,
                settingsView: permissions.settingsView,
                disbursementApprove: permissions.disbursementApprove,
                disbursementCreate: permissions.disbursementCreate,
                refundCreate: permissions.refundCreate,
                topupCreate: permissions.topupCreate,
                topupView: permissions.topupView,
                refundApprove: permissions.refundApprove,
                refundReject: permissions.refundReject,
            }
        }
    },
    methods: {
        showToaster(errMsg, timeout = 2000) {
            this.$toasted.error(errMsg, {
                theme: "bubble", // default
                duration: timeout
            });
        },
        showSuccessToaster(msg, timeout = 2000) {
            this.$toasted.success(msg, {
                theme: "bubble", // default
                duration: timeout
            });
        },
        // return true if error
        verifyHttpResponse(response) {
            if (response && response.data && response.data.data) return false;
            this.showToaster("Invalid response");
            return true;
        },
        validatePassword(password) {
            if (password === null) {
                return true;
            }
            const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
            return password && passwordPattern.test(password);
        },
        handleAPIError(e) {
            if (e &&
                e.response &&
                e.response.data &&
                e.response.data.error) {
                return this.showToaster(e.response.data.error);
            }
            this.showToaster("Invalid response");
        },
        getMerchantUserName() {
            return this.$store.getters.getMerchantName;
        },
        getUserEmail() {
            return this.$store.getters.getEmailID;
        },
        getCapitalized(text) {
            if (!text) return "";
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        getStatusCapitalized(text) {
            if (text) {
                const resultArr = text.split('_');
                const resultLength = resultArr.length;
                for (let i = 0; i < resultLength; i++) {
                    resultArr[i] = resultArr[i][0].toUpperCase() + resultArr[i].slice(1).toLowerCase();
                }
                return resultArr.join(' ');
            }
            return "";
        },
        getOrderBadge(status) {
            switch (status) {
                case "completed":
                    return "success";
                case "processing":
                    return "warning"
                case "paid":
                    return "secondary";
                case "partial":
                    return "warning";
                case "started":
                    return "info";
                default:
                    return "primary";
            }
        },
        getPaymentBadge(status) {
            switch (status) {
                case "completed":
                    return "success";
                case "processing":
                    return "warning";
                case "cancelled":
                    return "primary";
                case "failed":
                    return "danger";
                case "started":
                    return "info";
                default:
                    return "secondary";
            }
        },
        getPromoBadge(status) {
            switch (status) {
                case "active":
                    return "success";
                case "inactive":
                    return "primary";
                case "expired":
                    return "danger";
                case "paused":
                    return "warning";
                default:
                    return "primary";
            }
        },
        getPaymentLinkBadge(status) {
            switch (status) {
                case "paid":
                    return "success";
                case "unpaid":
                    return "warning";
                case "expired":
                    return "primary"
            }
        },
        getPromoTypeBadge(status) {
            switch (status) {
                case "percentage":
                    return "success";
                case "flat":
                    return "info";
                default:
                    return "primary";
            }
        },
        getRefundBadge(status) {
            switch (status) {
                case "started":
                    return "warning";
                case "approved":
                case "disbursement_processing":
                    return "info";
                case "rejected":
                case "disbursement_failed":
                    return "danger";
                case "done":
                    return "success";
                default:
                    return "primary";
            }
        },
        getDisbursementBadge(status) {
            switch (status) {
                case "success":
                    return "success";
                case "need_revision":
                    return "danger"
                case "need_approval":
                    return "warning";
                case "partial":
                    return "secondary";
                case "draft":
                    return "info";
                case "approved":
                    return "success";
                default:
                    return "primary";
            }
        },
        getDisbursementItemBadge(status) {
            switch (status) {
                case "done":
                    return "success";
                case "failed":
                    return "danger"
                case "invalid":
                    return "danger"
                case "processing":
                    return "warning";
                case "partial":
                    return "secondary";
                case "valid":
                    return "success";
                default:
                    return "primary";
            }
        },
        getPaymentOptionBadge(status) {
            switch (status) {
                case "full_payment":
                    return "success";
                case "installment":
                    return "danger"
                default:
                    return "primary";
            }
        },
        getType(isLive) {
            switch (isLive) {
                case true:
                    return this.$t("ORDERS.TABLE.LIVE");
                default:
                    return this.$t("ORDERS.TABLE.SANDBOX");
            }
        },
        getPayType(payType) {
            switch (payType) {
                case "card_details":
                    return "Credit Card";
                case "ewallet_details":
                    return "E-Wallets";
                default:
                    return "Virtual Account";
            }
        },
        getPaymentMethod(payType, method) {
            const paymentDetailType = this.getPayType(payType);
            return paymentDetailType + " - " + method;
        },
        removeDotsFromAmount(amount) {
            if (!amount) {
                return "0";
            }
            let length = amount.length;
            let resultantAmount = "";
            for (let key = 0; key < length; key++) {
                if (amount[key] === '.') {
                    continue;
                }
                resultantAmount += amount[key];
            }
            return resultantAmount;
        },
        formatAmount(amount) {

            return this.numberWithDots(amount);
        },
        cleanZeros(num) {
            return +num         //using unary operator deleted all preceeding zeros
        }, 
        numberWithCommas(amount) {
            if (!amount) return "0";
            let [beforeDec, afterDec] = amount.toString().split('.');
            let num = beforeDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (afterDec && parseInt(afterDec, 10) > 0) num = num + "." + afterDec;
            return num;
        },
        numberWithDots(amount) {
            if (!amount) return "0";
            amount = this.cleanZeros(amount);
            let [beforeDec, afterDec] = amount.toString().split('.');
            let num = beforeDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            if (afterDec && parseInt(afterDec, 10) > 0) num = num + "," + afterDec;
            return num;
        },
        formatDate(date) {
            if (date === "0001-01-01T00:00:00Z") return "-";
            return this.moment(date).format("DD-MMM-YYYY, HH:mm");
        },
        getCSVName(type) {
            let today = new Date()
            return type + '_' + this.moment(today).format("DD-MMM-YYYY").toString()
        },
        getTodayDate() {
            let today = new Date()
            return this.moment(today).format("YYYY-MM-DD").toString()
        },
        getDay(day) {
            switch (day) {
                case 0:
                    return 'Sun'
                case 1:
                    return 'Mon'
                case 2:
                    return 'Tue'
                case 3:
                    return 'Wed'
                case 4:
                    return 'Thur'
                case 5:
                    return 'Fri'
                case 6:
                    return 'Sat'
            }
        },
        getMonth(month) {
            switch (month) {
                case 0:
                    return 'Jan'
                case 1:
                    return 'Feb'
                case 2:
                    return 'Mar'
                case 3:
                    return 'Apr'
                case 4:
                    return 'May'
                case 5:
                    return 'Jun'
                case 6:
                    return 'July'
                case 7:
                    return 'Aug'
                case 8:
                    return 'Sep'
                case 9:
                    return 'Oct'
                case 10:
                    return 'Nov'
                case 11:
                    return 'Dec'
            }
        },
        getSettlementBadge(status) {
            switch (status) {
                case "settled":
                    return "success";
                case "need_to_settle":
                    return "primary";
                default:
                    return "secondary";
            }
        },
        validateEmail(email) {
            let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email && pattern.test(email);
        },
        validateRequiredField(val) {
            return !(val !== null && val.length === 0);
        },
        validatePhone(phoneNumber) {
            let pattern = /^(8\d{9,11}|0\d{9,12})$/;
            return pattern.test(phoneNumber);
        },
        validateNotes(notes) {
            if (notes.length > 18) {
              return false;
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        goTo(path, ...args) {
            let routeTo = path + args.join('/')
            this.$router.push(routeTo)
        },
        currencyOptions() {
            const options = ["IDR"]
            return options;
        },
        bankOptions() {
            const banks = ["BCA", "BNA"];
            return banks;
        },
        generateExpandParams(paramsArray) {
            const params = new URLSearchParams();
            let length = paramsArray.length;
            if (length === 0) return params.append("expand", JSON.stringify(paramsArray));
            for (let i = 0; i < length; i++) {
                params.append("expand", paramsArray[i]);
            }
            return params;
        },
        generatePostParams(paramsMap) {
            const params = new URLSearchParams();
            for (let key in paramsMap) params.append(key, paramsMap[key]);
            return params;
        },
        initializeDates() {
            return new Promise(resolve => {
                let today = new Date();
                let before = new Date();
                before.setDate(before.getDate() - 30);
                today = this.moment(today)
                    .format("MM-DD-YYYY")
                    .toString();
                before = this.moment(before)
                    .format("MM-DD-YYYY")
                    .toString();
                resolve({ today, before });
            });
        },
        minDate() { return new Date(2020, 0, 1, 0, 0, 0, 0); },
        maxDate() { return new Date(); },
        ifValid(data) {
            if (data !== undefined && data !== null) {
                return true;
            }
            return false;
        },
        resetToString(value) {
            if (value == 0) {
                return "";
            }
            return value;
        },
        getCurrencyLabel(currency, amount) {
            return currency + " " + this.formatAmount(amount);
        },
        copyToClipboard(str, msg) {
            const el = document.createElement("textarea");
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.showSuccessToaster(msg);
        },
        getWebhookEvents() {
            return [
                "payment.completed",
                "payment.failed",
                "payment.cancelled",
                "order.created",
                "order.completed",
                "disbursement.completed",
                "disbursement.failed",
            ];
        },
        getCurrentPageName() {
            return this.$route.name;
        },
        getPromoTypes() {
            return [
                {
                    type: "card_promos",
                    label: this.$t("PROMOS.TYPES.CARD")
                },
                {
                    type: "va_promos",
                    label: this.$t("PROMOS.TYPES.VA")
                },
                {
                    type: "ewallet_promos",
                    label: this.$t("PROMOS.TYPES.EWALLET")
                }
            ]
        },
        getPromoSubTypes() {
            return [{
                label: this.$t("PROMOS.LABELS.CASHBACK"),
                value: "cashback"
            },
            {
                label: this.$t("PROMOS.LABELS.DIRECT_DISCOUNT"),
                value: "direct_discount"
            },
            ]
        },
        getPromoLimitTypes() {
            return [
                {
                    label: this.$t("PROMOS.LABELS.QUOTA"),
                    value: "quota"
                },
                {
                    label: this.$t("PROMOS.LABELS.BUDGET"),
                    value: "budget"
                },
            ]
        },
        hasPermissionAccess(permission) {
            const pageViews = store.getters.getPageViews;
            if (pageViews.includes(permission)) {
                return true;
            }
            return false;
        },
        componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        },
        isHexValid(value) {
            return /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/.test(value)
        },
        rgbToHex(colorObject) {
            const { r, g, b } = colorObject;
            return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16),
                }
                : null;
        },
        trimEditableAmount(amount) {
            //If it is a decimal amount return the integer part of it
            if (amount && amount.split(".").length === 2) {
                return amount.split(".")[0];
            }
            return amount;
        },
        trimHash(color) {
            if (color && color[0] === '#') {
                return color.slice(1, color.length);
            }
            return color;
        },
        addHash(color) {
            if (color && color[0] !== "#") {
                return "#" + color;
            }
            return color;
        },
        capatalizeAndRemoveUnderScore(text) {
            if (text) {
                const splitText = text.split("_");
                let fullText = "";
                for (let key = 0; key < splitText.length; key++) {
                    fullText += splitText[key].charAt(0).toUpperCase() + splitText[key].slice(1) + " "
                }
                return fullText;
            }
            return text;
        },
        maskText(text) {
            if (text && typeof text !== 'string') return text;
            const split = text.split(' ');
            return split.map(i => {
                if (i.length <= 3) return i;
                return `${i.substr(0, 2)}${"●".repeat(i.length - 3)}${i.substr(-1)}`;
            }).join(' ');
        },
    }
}

export {
    mixin
}
