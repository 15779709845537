import * as types from "../mutation-types";

const defaultState = () => {
  return {
    pageViews: [],
    isDisbursementEnabled: false,
    isRefundEnabled: false,
  };
};

const state = defaultState();

const mutations = {
  [types.setPageViews](state, t) {
    state.pageViews = t;
  },
  [types.setIsDisbursementEnabled](state, t) {
    state.isDisbursementEnabled = t;
  },
  [types.setIsRefundEnabled](state, t) {
    state.isRefundEnabled = t;
  },
  [types.setDefaultPermissionState](state) {
    Object.assign(state, defaultState());
  }
};

const getters = {
  getPageViews: (state) => {
    return state.pageViews;
  },
  getIsDisbursementEnabled: (state) => {
    return state.isDisbursementEnabled;
  },
  getIsRefundEnabled: (state) => {
    return state.isRefundEnabled;
  },
};

export default { state, mutations, getters };
